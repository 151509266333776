<template>
  <div>

    <span class="misTerms" @click="showDialog = true">
      Datenquelle der mailVista-Adressdatenbank ist
      <img
        class="ma-3"
        width="100px"
        :src="require('@/assets/images/nitramit/mis-logo-w-60.png')"
      />
      <v-icon size="18" class="mr-1 ml-2">{{ icons.mdiInformationOutline }}</v-icon>
    </span>

    <v-dialog
      v-model="showDialog"
      width="900px"
    >
      <v-card>

        <v-toolbar flat>
          <v-toolbar-title>Media Information Systems</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showDialog = false">
            <v-icon size="18" >
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <p>
            Die MIS-Consumer Database ist das Herzstück der Media Information Systems.
            In über 15 Jahren hat sich die Datenbank zu einer festen Größe in der Data-Driven-Economy in Deutschland entwickelt.
            Als Grundlage von Marketingkampagnen, Analysen, Pflegereferenz oder Recherchequelle,
            ist diese Datenbank eine der umfangreichsten Quellen für eine datenschutzkonforme Nutzung deutscher Adressdaten.
          </p>

          <p>
            <strong>Datenbereitstellung für postalische Direktwerbung.</strong>
          </p>

          <p>
            Das haptische Werbemail hat heute noch eine herausragende Bedeutung für gewisse Produkte und Zielgruppen.
            Elektronische Medien erreichen heute bei Weitem nicht jeden und durch Angebote Bedürfnisse zu wecken,
            kann durch ein Briefmailing neue Kundengruppen erschließen. Spendenorganisationen, Versandhändler,
            Versicherungen und weiter Branchen vertrauen auf Adressen der MIS, da die breite Zielgruppenabdeckung,
            hohe Zustellbarkeitsquoten und der zuverlässige Dialog mit Betroffenen herausragende Qualitätsmerkmale der Media Information Systems darstellen.
            Wir verstehen uns als Partner der Data-Driven Economy mit dem Ziel wertschöpfende Kundenbeziehungen zu schaffen.
          </p>

          <p>
            Die Nutzung der Daten für postalisches Marketing ist nur im Rahmen des Lettershop-Verfahrens möglich.
            Das bedeutet, es kommt zu keiner Datenübermittlung an das Unternehmen, für dessen Angebote geworben wird.
            Im Zuge der Produktion des Mailings werden die Daten einem EDV- und Druckdienstleister übermittelt.
            Nach dem Druck bzw. der Personalisierung des Werbeschreibens werden die Daten durch den Dienstleister gelöscht.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            outlined
            @click="showDialog = false"
          >
            Schließen
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>


</template>

<script>

import {ref} from '@vue/composition-api'

import {
  mdiClose, mdiInformationOutline,
} from '@mdi/js'

export default {
  name: "MisTerms",
  setup(){

    const showDialog = ref(false);

    return {
      showDialog,
      icons:{
        mdiClose,
        mdiInformationOutline,
      }
    }
  }
};
</script>

<style scoped>
.misTerms{
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
}
img,a{
  cursor: pointer;
}

</style>
